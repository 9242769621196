// 资产台账
import request from '@/api/request'

export function fetchList(data) {
    return request({
        url: '/model-analysis/asset/material/list',
        method: 'post',
        data,
    })
}

export function add(data) {
    return request({
        url: '/model-analysis/asset/material/add',
        method: 'post',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/model-analysis/asset/material/delete',
        method: 'post',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/model-analysis/asset/material/update',
        method: 'post',
        data,
    })
}