import request from '@/api/request'

export function fetchList(params) {
    return request({
        url: '/model-analysis/asset/consume/list',
        params,
    })
}

export function add(data) {
    return request({
        url: '/model-analysis/asset/consume/add',
        method: 'post',
        data,
    })
}

export function fetchDetail({ id }) {
    return request({
        url: '/model-analysis/asset/consume/query/' + id,
    })
}

// 审核通过
export function review(data) {
    return request({
        url: '/model-analysis/asset/consume/review',
        method: 'post',
        data,
    })
}
// 出库
export function accept(data) {
    return request({
        url: '/model-analysis/asset/consume/accept',
        method: 'post',
        data,
    })
}

// 取消申请
export function cancel(data) {
    return request({
        url: '/model-analysis/asset/consume/cancel',
        method: 'post',
        data,
    })
}

// 删除
export function remove(data) {
    return request({
        url: '/model-analysis/asset/consume/delete',
        method: 'post',
        data,
    })
}

// 重新发起审批
export function edit(data) {
    return request({
        url: '/model-analysis/asset/consume/update',
        method: 'post',
        data,
    })
}