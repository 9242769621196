var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.disabled)?_c('div',{staticClass:"right",staticStyle:{"padding-bottom":"2vh"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("添加")])],1):_vm._e(),_c('a-table',{attrs:{"bordered":"","size":"small","dataSource":_vm.selected,"rowKey":"id","pagination":false}},[_c('a-table-column',{key:"code",attrs:{"title":"资产编号","data-index":"code"}}),_c('a-table-column',{key:"name",attrs:{"title":"资产名称","data-index":"name"}}),_c('a-table-column',{key:"brand",attrs:{"title":"品牌","data-index":"brand"}}),_c('a-table-column',{key:"specification",attrs:{"title":"规格型号","data-index":"specification"}}),_c('a-table-column',{key:"unit",attrs:{"title":"单位","data-index":"unit"}}),_c('a-table-column',{key:"price",attrs:{"title":"单价(元)","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('Money',{attrs:{"money":text.price}})]}}])}),(!_vm.disabled)?_c('a-table-column',{key:"control",attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, row, index){return [_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_vm._v("删除")])]}}],null,false,678161070)}):_vm._e()],1),_c('a-modal',{attrs:{"title":"选择资产","visible":_vm.visible,"width":"800px"},on:{"cancel":_vm.cancel,"ok":_vm.ok}},[_c('a-table',{attrs:{"bordered":"","size":"small","dataSource":_vm.list,"rowKey":"id","row-selection":{
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
      },"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }},on:{"change":_vm.onChange}},[_c('a-table-column',{key:"code",attrs:{"title":"资产编号","data-index":"code"}}),_c('a-table-column',{key:"name",attrs:{"title":"资产名称","data-index":"name"}}),_c('a-table-column',{key:"brand",attrs:{"title":"品牌","data-index":"brand"}}),_c('a-table-column',{key:"specification",attrs:{"title":"规格型号","data-index":"specification"}}),_c('a-table-column',{key:"unit",attrs:{"title":"单位","data-index":"unit"}}),_c('a-table-column',{key:"price",attrs:{"title":"单价(元)","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('Money',{attrs:{"money":text.price}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }